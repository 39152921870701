import styled from "styled-components";

import Paper from "@material-ui/core/Paper";

export const HeatmapGrid = styled.div`
  overflow-x: auto;
  display: flex;
`;

export const Heatmap = styled.div`
  display: flex;
`;

export const Spotlight = styled.div`
  width: 500px;
  border-radius: 5px;
  height: 300px;
  background-color: white;
  margin: 0px auto;
  padding: 20px;
  margin-top: 150px;
  overflow: scroll;
`;

export const SpotlightNurses = styled.div`
  margin-top: 20px;
  height: 200px;
`;

export const SpotlightNurseCell = styled.div`
  margin-bottom: 10px;
`;

export const TimeLabel = styled.div`
  height: 45px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid whitesmoke;
  background-color: white;
`;

export const DayColumns = styled.div`
  width: fit-content;
`;

export const Day = styled.div`
  width: 123px;
  height: 100%;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  ::-webkit-scrollbar {
    display: none !important;
  }
  display: inline-block;
  background-color: white;
`;

export const DaySlot = styled.div`
  height: 45px;
  width: 123px;
  border: 1px solid var(--whitesmoke);
  display: flex;
  align-items: center;
  justify-content: center;
  &:nth-child(even) {
    background-color: whitesmoke;
  }

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: #ffc600;
    color: rgb(72, 72, 72);
  }
`;

export const DateLabel = styled.div`
  width: 121px;
  height: 45px;
  border: 1px solid whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const DashopsWrap = styled(Paper)`
  padding: 40px;
  display: inline-block;
`;

export const NoResults = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 0px;
  border: 1px solid #d5d5d5;
  background-color: #EEE;
  border-radius: 5px;
`
