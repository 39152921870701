import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import classnames from "classnames";
import moment from "moment";
import styled from "styled-components";
import {
  DashopsWrap,
  DateLabel,
  Day,
  DayColumns,
  DaySlot,
  Heatmap,
  HeatmapGrid,
  Spotlight,
  SpotlightNurseCell,
  TimeLabel,
  NoResults,
} from "../../styles/HealthTeam/capacity";
import nurseService from "../../utils/api/v1/nurseService";
import { InputLabel, MenuItem, Select, Modal } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import Error from "../../components/Errors/Error";

// other files
import places from "../../utils/comunas/places.json";
import { COUNTRIES } from "../../utils/data/constants";

const SERVICE_TYPES = {
  blood: "Sangre",
  urine: "Orina",
  covid: "Covid",
  kine: "Kine",
  vaccine: "Vacunas",
  ptgo: "PTGO",
};

const heatmapColors = {
  0: "red",
  1: "#87dfff",
  2: "#87dfff",
  3: "#87dfff",
  4: "#87dfff",
  5: "#7ec4fd",
  6: "#7ec4fd",
  7: "#7ec4fd",
  8: "#7ec4fd",
  9: "#46a5f5",
  10: "#46a5f5",
  11: "#46a5f5",
  12: "#46a5f5",
  13: "#305ec4",
  14: "#305ec4",
  15: "#305ec4",
  16: "#305ec4",
  17: "blue",
  18: "blue",
  19: "blue",
  20: "blue",
  21: "#2d00df",
  22: "#2d00df",
  23: "#2d00df",
  24: "#2d00df",
  25: "#5000bf",
};

const CommuneSelect = styled(Select)`
  width: 100%;
  max-width: 300px;
`;

const ServiceTypeSelect = styled(Select)`
  width: 100%;
  max-width: 300px;
`;

const RegionSelect = styled(Select)`
  width: 100%;
  max-width: 300px;
`;

const PrimaryButton = styled(Button)`
  max-height: 2.5rem;
  border-color: var(--primary);
  color: var(--primary);
`;

const HealthTeamCapacity = () => {
  const [heatmap, setHeatmap] = useState({});
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [capacityCountry, setCapacityCountry] = useState<"Chile" | "México">(
    "Chile"
  );
  const [capacityRegion, setCapacityRegion] = useState<string>(
    "Región Metropolitana de Santiago"
  );
  const [capacityComuna, setCapacityComuna] = useState<string[]>(["Alhué"]);
  const [comunaValue, setComunaValue] = useState<string>("Alhué");
  const [serviceTypeValue, setServiceTypeValue] = useState<string>("all");
  const [fingerprintAvailable, setFingerprintAvailable] = useState<
    "true" | "false" | "all"
  >("all");
  const [dates, setDates] = useState([]);
  const [capacityHourLabels, setCapacityHourLabels] = useState([]);
  const [spotlight, setSpotlight] = useState([]);
  const [showSpotlight, setShowSpotlight] = useState(false);
  const [spotlightLabel, setSpotlightLabel] = useState({});

  const heatMapRef = useRef<HTMLDivElement | null >(null);

  let regiones = Object.keys(places[capacityCountry]);
  let comunas =
    capacityRegion !== "all" ? places[capacityCountry][capacityRegion] : [];

  const fetchHeatmap = async (comunas, serviceType: string) => {
    setLoading(true);
    setErrors([]);
    try {
      const fetchedHeatmap = await nurseService.fetchHeatMap(
        comunas,
        serviceType,
        fingerprintAvailable,
        capacityCountry
      );
      let tempHeatmap = fetchedHeatmap.data.data;
      setHeatmap(tempHeatmap);
      let days = Object.keys(tempHeatmap);

      // Temporarily store name and value
      let tempHourLabels = ["", 0, []];

      days.forEach((day) => {
        let hourLabels = Object.keys(tempHeatmap[`${day}`]).sort((a, b) =>
          a > b ? 1 : b > a ? -1 : 0
        );
        let hourLengths = hourLabels.length;
        if (hourLengths > tempHourLabels[1]) {
          tempHourLabels = [day, hourLengths, hourLabels];
        }
      });

      setDates(days);
      setCapacityHourLabels(tempHourLabels[2]);
    } catch (err) {
      console.error(err);
      setErrors([err.message]);
    }
    setLoading(false);

  };

  const handleCommuneChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    if (e.target.value === "all") {
      comunas = places[capacityCountry][capacityRegion];
      setCapacityComuna(comunas);
      setComunaValue("all");
    } else {
      setCapacityComuna([e.target.value as string]);
      setComunaValue(e.target.value as string);
    }
  };

  const handleServiceTypeChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setServiceTypeValue(e.target.value as string);
  };

  const handlefingerprintAvailableChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setFingerprintAvailable(e.target.value as string);
  };

  const handleRegionChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setCapacityRegion(e.target.value as string);
    if (e.target.value === "all") {
      const comunesArrayofArrays = Object.values(places[capacityCountry]);
      comunas = [].concat.apply([], comunesArrayofArrays); // flattens array of arrays
      setCapacityComuna(comunas);
    } else {
      comunas = places[capacityCountry][e.target.value as string];
      setCapacityComuna(comunas);
    }
    setComunaValue("all");
  };

  const handleCountryChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const country = e.target.value as string;
    setCapacityCountry(country);
    regiones = Object.keys(places[country]);
    setCapacityRegion(regiones[0]);
    comunas = places[country][regiones[0]];
    setCapacityComuna(comunas);
  };

  const onWheelHandler = (e) => {
    if (!e.deltaY) {
      return;
    }
    e.currentTarget.scrollLeft += e.deltaY + e.deltaX;
    e.preventDefault();
  }

  useEffect(() => {
    fetchHeatmap(capacityComuna, serviceTypeValue);
  }, []);

  useLayoutEffect(() => {
    const element = heatMapRef.current;
    element?.addEventListener("wheel", onWheelHandler)
    return () => {
      element?.removeEventListener("wheel", onWheelHandler)
    }
  });

  return (
    <PrivateRoute>
      <DashopsWrap variant="outlined" style={{
        display: "flex",
        rowGap: "2rem",
        flexDirection: "column"
      }}>
        <Modal
          open={showSpotlight}
          onClose={() => {
            setShowSpotlight(false);
            document.getElementById("navbar").style.zIndex = "1999";
          }}
        >
          <Spotlight>
            <h4>
              {spotlightLabel.day} a las {spotlightLabel.hour}
            </h4>
            <h5 style={{ marginTop: 20, marginBottom: 10 }}>
              Health Team Disponible
            </h5>
            {spotlight.map((item, i) => (
              <SpotlightNurseCell>
                <Link key={i} to={`/health-team/${item.id}`}>
                  {item.names} {item.last_names}
                </Link>
              </SpotlightNurseCell>
            ))}
          </Spotlight>
        </Modal>
        <div className="w-full">
          <h2>Horas disponibles para pacientes</h2>
          <p>Son las opciones de agendamiento que pueden ver los pacientes</p>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <InputLabel>País</InputLabel>
            <RegionSelect
              value={capacityCountry}
              label="País"
              onChange={handleCountryChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {COUNTRIES.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </RegionSelect>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>
              {capacityCountry === "México" ? "Estado" : "Region"}
            </InputLabel>
            <RegionSelect
              value={capacityRegion}
              label={capacityCountry === "México" ? "Estado" : "Region"}
              onChange={handleRegionChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="all">Todas</MenuItem>
              {regiones.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </RegionSelect>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>
              {capacityCountry === "México" ? "Municipio" : "Comuna"}
            </InputLabel>
            <CommuneSelect
              value={comunaValue}
              label={capacityCountry === "México" ? "Municipio" : "Comuna"}
              onChange={handleCommuneChange}
              disabled={capacityRegion === "all"}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="all">Todas</MenuItem>
              {comunas.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </CommuneSelect>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Categoría del Servicio</InputLabel>
            <ServiceTypeSelect
              value={serviceTypeValue}
              label="Categoría de Servicio"
              onChange={handleServiceTypeChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="all">Todos</MenuItem>
              {Object.entries(SERVICE_TYPES).map(([key, value]) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
            </ServiceTypeSelect>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Tiene Huellero</InputLabel>
            <ServiceTypeSelect
              value={fingerprintAvailable}
              label="Huellero disponible"
              onChange={handlefingerprintAvailableChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="true">Sí</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </ServiceTypeSelect>
          </Grid>
          <Grid item xs={4}>
            <PrimaryButton
              variant="outlined"
              color="primary"
              onClick={() => {
                fetchHeatmap(capacityComuna, serviceTypeValue);
              }}
            >
              Filtrar
            </PrimaryButton>
          </Grid>
          <Error>
            <ul>
              {errors.map((i, key) => (
                <li key={key}>{i}</li>
              ))}
            </ul>
          </Error>
        </Grid>
        {loading &&  <p>Cargando...</p> }
        {!loading && (
          <>
            {(!capacityHourLabels || capacityHourLabels.length === 0) && (
              <NoResults>No hay resultados</NoResults>
            )}
            {capacityHourLabels && capacityHourLabels.length > 0 && (
              <Heatmap>
                <DayColumns style={{width: "fit-content"}}>
                  {capacityHourLabels?.length ? <TimeLabel /> : null}
                  {capacityHourLabels?.map((item, i) => (
                    <TimeLabel key={i}>{item}</TimeLabel>
                  ))}
                </DayColumns>
                <HeatmapGrid ref={heatMapRef}>
                  {dates?.map((date, i) => (
                    <Day key={i}>
                      {capacityHourLabels.map((hourLabel, k) => {
                        if (
                          heatmap[`${date}`] &&
                          heatmap[`${date}`][`${hourLabel}`]
                        ) {
                          const capacityInfo = heatmap[`${date}`][`${hourLabel}`];
                          return (
                            <>
                            {k === 0 && (
                              <DateLabel>
                              {moment(date).locale("es").format("ddd Do")}
                            </DateLabel>
                            )}
                            <DaySlot
                              className={classnames({
                                active: capacityInfo === spotlight,
                              })}
                              key={k}
                              onClick={() => {
                                setSpotlightLabel({
                                  day: date,
                                  hour: hourLabel,
                                });
                                setSpotlight(capacityInfo);
                                setShowSpotlight(true);
                                document.getElementById("navbar").style.zIndex = "0";
                              }}
                              style={{
                                backgroundColor:
                                  heatmapColors[`${capacityInfo.length}`],
                              }}
                            >
                              <p style={{ color: "white", fontWeight: 500 }}>
                                {capacityInfo.length}
                              </p>
                            </DaySlot>
                            </>
                          );
                        } else {
                          return (
                            <>
                            {k === 0 && (
                              <DateLabel>
                                {moment(date).locale("es").format("ddd Do")}
                              </DateLabel>
                            )}
                            <DaySlot
                              key={k}
                              style={{
                                backgroundColor: "red",
                              }}
                            >
                              <p style={{ color: "white", fontWeight: 500 }}>0</p>
                            </DaySlot>
                            </>
                          );
                        }
                      })}
                    </Day>
                  ))}
                </HeatmapGrid>
              </Heatmap>
            )}
          </>
        )}
      </DashopsWrap>
    </PrivateRoute>
  );
};

export default HealthTeamCapacity;
